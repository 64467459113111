<template>
	<div class="container">
	  <div class="grid justify-content-center  my-6">
		<div class="col-12 sm:col-8 md:col-5 comp-grid">
			<div class="flex align-items-center mb-3">
              <div v-if="!isSubPage" class="col-fixed">
                <Button
                  @click="$router.go(-1)"
                  label=""
                  icon="pi pi-arrow-left"
                  class="p-button-sm p-button-rounded px-4"
                />
              </div>
              <div class="col-12 md:col-10">
                <div class="text-lg text-primary font-bold">OTP Verification code sent to Email</div>
              </div>
            </div>
		  <Message
			@close="errorMsg = null"
			class="fadeinUp"
			v-if="errorMsg"
			severity="error"
			key="error"
			>{{ errorMsg }}</Message
		  >
		  <div class="card shadow-3 py-6">
			<div class="mb-4">
			  <div class="grid align-items-center justify-content-center">
				<div class="text-primary">
				A verification code has been sent to your email
			  </div>
			  </div>
			</div>
			<form
			  ref="observer"
			  tag="form"
			  @submit.prevent="startOtpValidation()"
			>
			  <div class="grid justify-content-between">
				<div class="col-12 md:col-12">
				  <InputText
					class="w-full otp-input"
					placeholder="Enter Code"
					v-model="otpCode"
					required="required"
					type="text"
					maxLength="6"
					minLength="4"
				  />
				</div>
				<div class="col-12 md:col-12">
				  <Button
					class="p-button-md w-full py-3"
					:loading="loading"
					type="submit"
					:label="`Verify (${countDown})`"
				  />
				</div>
			  </div>
			  <div class="flex align-items-center justify-content-center">
				<div class="mt-3">
					Didn't receive OTP?
				  <Button
					class="p-button-text p-0 ml-2"
					:disabled="!canResend"
					:loading="resending"
					@click="resendOtp()"
					type="button"
					label="Resend Code"
				  />
				</div>
			  </div>
			</form>
		  </div>
		</div>
	  </div>
	</div>
  </template>
<script>
	export default {
		props: {},
		data: function () {
			return {
				otpCode: '',
				loading: false,
				resending: false,
				canResend: false,
				errorMsg: null,
				countDown: '00:00',
			};
		},
		methods: {
			async startOtpValidation() {
				if (this.otpCode) {
					this.loading = true;
					this.errorMsg = '';
					let formData = {
						otp_code: this.otpCode,
						token: this.$route.query.token
					}
					let rememberUser = false;
					let url = "/auth/validateotp";
					let payload = {
						formData,
						rememberUser,
						url
					};
					this.$store.dispatch('auth/login', payload).then((loginData) => {
						this.loading = false;
						if (loginData.token) {
							window.location = "/"; //user is now logged in. Navigate to home page
						}
						else{
							this.$router.push(loginData.nextpage);
						}
					},
					(request) => {
						this.loading = false;
						this.errorMsg = request?.response?.data || "Unable to validate otp";
					});
				}
			},
			async resendOtp() {
				this.resending = true;
				this.errorMsg = '';
				let formData = {
					token: this.$route.query.token
				}
				let url = "/auth/resendotp";
				this.$api.post(url, formData).then((response) => {
					this.resending = false;
					this.canResend = false;
					this.startCountDown();
					this.$toast.add({severity:'success', detail:'OTP Sent Successfully'});
				},
					(request) => {
						this.resending = false;
						this.errorMsg = request?.response?.data || "Unable to resend otp";
					});
			},
			startCountDown() {
				let duration = parseInt(this.$route.query.duration) || 5; // in minutes
				let minutes = 60 * duration;
				let timer = minutes;
				let seconds;
				var self = this;
				const interval = setInterval(function () {
					minutes = parseInt(timer / 60, 10);
					seconds = parseInt(timer % 60, 10);
					minutes = minutes < 10 ? "0" + minutes : minutes;
					seconds = seconds < 10 ? "0" + seconds : seconds;
					self.countDown = minutes + ":" + seconds;
					if (--timer < 0) {
						clearInterval(interval);
						self.errorMsg = "OTP has expired";
						self.canResend = true;
					}
				}, 1000);
			}
		},
		mounted() {
			this.startCountDown();
		},
	};
</script>
<style>
	.otp-input {
		font-weight: bold;
		font-size: 16px;
		text-align: center;
		letter-spacing: 10px;
	}
	.otp-input::placeholder {
		font-weight: normal;
		font-size: 14px;
		text-align: center;
		letter-spacing: 1px;
	}
</style>